import React from 'react'
import './Preloader.css';
const Preloader = () => {
  return (
    
      <div id="preloader">
  <div id="loader"></div>
</div>
    
  )
}

export default Preloader
