// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutSection{
      background-color: #ffffff;
 
}
.ContainerAboutus{
    padding-top: 5em;
    padding-bottom: 5em;
}

.titleAbout{
    font-size: 2.5em;
    font-weight: bold;
    text-transform: capitalize;
}
.AboutMeDetails{
    margin-top: 30px;
    text-align:justify;
}
.Skills{
    
    padding-left: 20px;
    padding-right: 20px;
}
.SkillsLane{
    display: flex;
    justify-content: space-between;
}
.Skill{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.Skill img{
    width: 80px;
    filter: grayscale(100);
    transition: all 200ms linear;
}
.Skill img:hover{
    transform: scale(1.1);
    filter: grayscale(0);
}
`, "",{"version":3,"sources":["webpack://./src/Components/AboutMe/AboutSection.css"],"names":[],"mappings":"AAAA;MACM,yBAAyB;;AAE/B;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;AAC9B;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;;IAEI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,sBAAsB;IACtB,4BAA4B;AAChC;AACA;IACI,qBAAqB;IACrB,oBAAoB;AACxB","sourcesContent":[".AboutSection{\n      background-color: #ffffff;\n \n}\n.ContainerAboutus{\n    padding-top: 5em;\n    padding-bottom: 5em;\n}\n\n.titleAbout{\n    font-size: 2.5em;\n    font-weight: bold;\n    text-transform: capitalize;\n}\n.AboutMeDetails{\n    margin-top: 30px;\n    text-align:justify;\n}\n.Skills{\n    \n    padding-left: 20px;\n    padding-right: 20px;\n}\n.SkillsLane{\n    display: flex;\n    justify-content: space-between;\n}\n.Skill{\n    display: flex;\n    justify-content: center;\n    margin-top: 30px;\n    margin-bottom: 30px;\n}\n.Skill img{\n    width: 80px;\n    filter: grayscale(100);\n    transition: all 200ms linear;\n}\n.Skill img:hover{\n    transform: scale(1.1);\n    filter: grayscale(0);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
