// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectinfo .heading{
    font-size:1.5em;
    font-weight: bold;
}
.projectinfo .description{
    font-size: 1em;
    font-weight: 300;
    margin-top: 10px;
    
}
.DetailsPoints{
    margin-top: 40px;
    margin-bottom: 40px;
}
.codeDescriptionProjects{
    display: flex;
}
.ImageProject{
    background-color: #eaeaea;
    padding: 10px;
    border-radius: 10px;
}
.ImageProject img{
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Projects/ProjectModal/ProjectModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;;AAEpB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,WAAW;AACf","sourcesContent":[".projectinfo .heading{\n    font-size:1.5em;\n    font-weight: bold;\n}\n.projectinfo .description{\n    font-size: 1em;\n    font-weight: 300;\n    margin-top: 10px;\n    \n}\n.DetailsPoints{\n    margin-top: 40px;\n    margin-bottom: 40px;\n}\n.codeDescriptionProjects{\n    display: flex;\n}\n.ImageProject{\n    background-color: #eaeaea;\n    padding: 10px;\n    border-radius: 10px;\n}\n.ImageProject img{\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
