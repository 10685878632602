// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagenotfound{
    color: #ffffff;
    margin-top: 120px;
    
    
    
    
    
}
.pagenotfound .Heading{
font-size: 4em;
font-weight: 700;
text-transform: uppercase;
}
.pagenotfound .Desc{
    font-size: 2em;
}
.pagenotfound .ResumeBtn{
    margin-left: 0 !important;
}
.pagenotfound .Image{
    text-align: right;

}
`, "",{"version":3,"sources":["webpack://./src/Components/ErrorPage/ErrorPage.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;;;;;;AAMrB;AACA;AACA,cAAc;AACd,gBAAgB;AAChB,yBAAyB;AACzB;AACA;IACI,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,iBAAiB;;AAErB","sourcesContent":[".pagenotfound{\n    color: #ffffff;\n    margin-top: 120px;\n    \n    \n    \n    \n    \n}\n.pagenotfound .Heading{\nfont-size: 4em;\nfont-weight: 700;\ntext-transform: uppercase;\n}\n.pagenotfound .Desc{\n    font-size: 2em;\n}\n.pagenotfound .ResumeBtn{\n    margin-left: 0 !important;\n}\n.pagenotfound .Image{\n    text-align: right;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
