// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  background-color: #000000 !important;
  color: #ffffff !important;
  font-family: 'Montserrat', sans-serif !important;
  overflow-x: hidden;
  

}
html {
  overflow-x: hidden;
}
.mt-20{
  margin-top: 20px;
}
.greenyellowline{
  height: 5px;
  background: linear-gradient(90deg, #000 5%,#20BC5A 45%,#C9FE01);
  width: 100%;
  margin-top: 5px;
  
}
.purpleblueline{
  height: 5px;
  background: linear-gradient(90deg, #fc466b 10%, #3f5efb 80%);
  width: 100%;
  margin-top: 5px;
  
}

.ModalDetails .modal-backdrop.show {
  opacity: 0.1 !important;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,gDAAgD;EAChD,kBAAkB;;;AAGpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,+DAA+D;EAC/D,WAAW;EACX,eAAe;;AAEjB;AACA;EACE,WAAW;EACX,4DAA4D;EAC5D,WAAW;EACX,eAAe;;AAEjB;;AAEA;EACE,uBAAuB;EACvB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\nbody{\n  background-color: #000000 !important;\n  color: #ffffff !important;\n  font-family: 'Montserrat', sans-serif !important;\n  overflow-x: hidden;\n  \n\n}\nhtml {\n  overflow-x: hidden;\n}\n.mt-20{\n  margin-top: 20px;\n}\n.greenyellowline{\n  height: 5px;\n  background: linear-gradient(90deg, #000 5%,#20BC5A 45%,#C9FE01);\n  width: 100%;\n  margin-top: 5px;\n  \n}\n.purpleblueline{\n  height: 5px;\n  background: linear-gradient(90deg, #fc466b 10%, #3f5efb 80%);\n  width: 100%;\n  margin-top: 5px;\n  \n}\n\n.ModalDetails .modal-backdrop.show {\n  opacity: 0.1 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
