// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleAboutDetails{
    font-size: 2.5em;
    font-weight: bold;
    color: #ffffff;
    text-transform: capitalize;
}
.DetailsAboutMeSec{
    color: #ffffff !important;
}
.Dets .info .CompanyName{
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 2px;
}
.Dets .info .Title{
    font-size: 1.5em;
    
    font-weight: 500;
}
.Dets .info .others{
    
    margin-top: 10px;
    font-weight: 300;
    color: #9ca3af;
    
}
.Dets .description{
    font-size: 1em;

}
.ReadMoreButton{
    text-decoration: underline;
    cursor: pointer;

}
.Details{
    margin-top: 50px;
}
.Detail{
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.CodeIcon .codeTag {
    color: #71717a;
  }`, "",{"version":3,"sources":["webpack://./src/Components/DetailsAboutMe/DetailsAboutMe.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,0BAA0B;AAC9B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,gBAAgB;;IAEhB,gBAAgB;AACpB;AACA;;IAEI,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;;AAElB;AACA;IACI,cAAc;;AAElB;AACA;IACI,0BAA0B;IAC1B,eAAe;;AAEnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;EAChB","sourcesContent":[".titleAboutDetails{\n    font-size: 2.5em;\n    font-weight: bold;\n    color: #ffffff;\n    text-transform: capitalize;\n}\n.DetailsAboutMeSec{\n    color: #ffffff !important;\n}\n.Dets .info .CompanyName{\n    font-size: 1.2em;\n    font-weight: bold;\n    letter-spacing: 2px;\n}\n.Dets .info .Title{\n    font-size: 1.5em;\n    \n    font-weight: 500;\n}\n.Dets .info .others{\n    \n    margin-top: 10px;\n    font-weight: 300;\n    color: #9ca3af;\n    \n}\n.Dets .description{\n    font-size: 1em;\n\n}\n.ReadMoreButton{\n    text-decoration: underline;\n    cursor: pointer;\n\n}\n.Details{\n    margin-top: 50px;\n}\n.Detail{\n    display: flex;\n    margin-top: 30px;\n    margin-bottom: 30px;\n}\n\n.CodeIcon .codeTag {\n    color: #71717a;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
