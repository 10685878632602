// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop.show {
    opacity: 0.8 !important;
    }
    .ModelDetails{
        color: #000000 !important;
    }
    .modal-header{
        border-bottom: none !important;
    }
    .btn-close:focus{
        box-shadow: none !important
    }
    .modal-content{
        /* background-color: #000000 !important; */
    }
    
    .DetailsPoints{
        margin-top: 30px;
    }
    .Credit{
        display: flex;
    }`, "",{"version":3,"sources":["webpack://./src/Components/Credits/CreditsModal/CreditsModal.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB;IACA;QACI,yBAAyB;IAC7B;IACA;QACI,8BAA8B;IAClC;IACA;QACI;IACJ;IACA;QACI,0CAA0C;IAC9C;;IAEA;QACI,gBAAgB;IACpB;IACA;QACI,aAAa;IACjB","sourcesContent":[".modal-backdrop.show {\n    opacity: 0.8 !important;\n    }\n    .ModelDetails{\n        color: #000000 !important;\n    }\n    .modal-header{\n        border-bottom: none !important;\n    }\n    .btn-close:focus{\n        box-shadow: none !important\n    }\n    .modal-content{\n        /* background-color: #000000 !important; */\n    }\n    \n    .DetailsPoints{\n        margin-top: 30px;\n    }\n    .Credit{\n        display: flex;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
