// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop.show {
opacity: 0.8 !important;
}
.ModelDetails{
    color: #000000 !important;
}
.modal-header{
    border-bottom: none !important;
}
.btn-close:focus{
    box-shadow: none !important
}
.modal-content{
    /* background-color: #000000 !important; */
}

.DetailsPoints{
    margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/DetailsAboutMe/DetailsModal/DetailsModal.css"],"names":[],"mappings":"AAAA;AACA,uBAAuB;AACvB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,8BAA8B;AAClC;AACA;IACI;AACJ;AACA;IACI,0CAA0C;AAC9C;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".modal-backdrop.show {\nopacity: 0.8 !important;\n}\n.ModelDetails{\n    color: #000000 !important;\n}\n.modal-header{\n    border-bottom: none !important;\n}\n.btn-close:focus{\n    box-shadow: none !important\n}\n.modal-content{\n    /* background-color: #000000 !important; */\n}\n\n.DetailsPoints{\n    margin-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
